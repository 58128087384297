.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.label {
  padding-bottom: 16px;
}

.link {
  padding: 16px;
  border-radius: 5px;
  background-color: #343434;
  font-size: 12px;
  font-family: monospace;
  color: #e1e1e1;
}
