html,
body,
#root,
.app-container {
  height: 100dvh;
  width: 100%;
}

body {
  margin: 0;
  background-color: #000000;
  font-family: sans-serif;
  color: white;
}
