.container {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButton {
  margin: 0 14px;
}

.errorMessage {
  text-align: center;
  padding-bottom: 20px;
}
