.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  text-align: center;
  padding-bottom: 20px;
}
